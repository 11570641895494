import React, { useEffect, useState } from 'react'
import Caminodisplay from './Caminodisplay'
import axios from 'axios';

export default function Camino() {
  const [eventos, setEventos] = useState([]);
  const [selected, setSelected] = useState('');
  const [especialidades, setEspecialidades] = useState([]);
  const getEspecialidades = async () => {
    await axios.get('http://localhost:4000/especialidades').then((response => {
      setEspecialidades(response.data.data)
    }))
  }
  const getEventos = async () => {
    await axios.get('http://localhost:4000/calendario/22').then((response => {
      setEventos(response.data.data)
    }))
  }
  useEffect(() => {
    getEventos()
    getEspecialidades()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])
  return (
    <div>
      <div className=''>
        <div className='text-center font-semibold text-white'>Selecciona una Especialidad</div>
        <select name="especialidad" id='especialidad' className='w-full h-8 flex-grow bg-violet-700 text-white rounded-lg  text-center pt-1 py-1 font-bold'
          onChange={(e) => { setSelected(e.target.value) }}>
          {especialidades.map((esp, index) => {
            return (<option key={index} value={esp.PROFESION}>{esp.PROFESION}</option>)
          })}
        </select>
      </div>
      <div className='flex gap-2 flex-wrap flex-grow-0 justify-evenly pt-1 bg-violet-600 rounded-lg'>
        {eventos.map((evs, index) => {
          return (
            <Caminodisplay key={index}
              id={evs.id}
              backgroundColor={evs.backgroundColor}
              CODGUIA={evs.CODGUIA}
              start={evs.start}
              end={evs.end}
              NOMGUIA={evs.NOMGUIA}
              PROFESION={evs.PROFESION}
              NOMCAMINANTE={evs.NOMCAMINANTE}
            />
          )
        }
        )}
      </div>
    </div>
  )
}