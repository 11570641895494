import React, { useState, useEffect } from 'react'
import axios from 'axios';
import useSessionStorageState from 'use-session-storage-state'
import Swal from 'sweetalert2'

const Signin = (params) => {
    // eslint-disable-next-line no-unused-vars
    const [admin, setAdmin] = useSessionStorageState('admin')
    // eslint-disable-next-line no-unused-vars
    const [logged, setLogged] = useSessionStorageState('logged');
    // eslint-disable-next-line no-unused-vars
    const [usuario, setUsuario] = useSessionStorageState('usuario');
    const [grupo, setGrupo] = useSessionStorageState('grupo');
    // eslint-disable-next-line no-unused-vars
    const [ok, setOk] = useState(true);
    //eslint-disable-next-line no-unused-vars
    const [inputs, setInputs] = useState([]);
    const handleChanges = (event) => {
        event.preventDefault();
        setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
    }
    const validaCampos = () => {
        console.log(admin, usuario, logged)
        var elem = document.getElementsByTagName('input');
        var errors = 0;
        for (let i = 0; i < elem.length; i++) {
            if (elem[i].value === "" || elem[i].value === " ") {
                ++errors;
                elem[i].className = " bg-orange-100 values relative block w-full appearance-none rounded-none rounded-t-md border border-grey500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            };
        }
        if (errors !== 0) {
            return false;
        } else
            return true;
    }
    // eslint-disable-next-line no-extend-native
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const validaPass = (event) => {
        event.preventDefault();
        if (validaCampos) {
            let inputuser = inputs.username
            let inputpass = inputs.password
            const fetchData = async () => {
                try {
                    const response = await axios.post("https://familia.caminantes.cl/user/validation/", {
                        method: "GET",
                        mode: "cors",  // Change the mode to CORS
                        params: {
                            rutusuario: inputuser,
                            password: inputpass
                        }
                    }
                    );
                    if (response.status === 200) {
                        if (response.data.data.length === 1) {
                            document.getElementById('msgError').innerText = ""
                            setLogged(true);
                            document.getElementById('msgError').innerText = ""
                            setLogged(true);
                            setUsuario(response.data.data[0])
                            setAdmin(response.data.data[0].TIPUSUARIO === 2 ? true : false);
                            setGrupo(response.data.data[0].GRUPO)
                            Toast.fire({
                                icon: "success",
                                title: "Te damos la bienvenida " + response.data.data[0].NOMUSUARIO
                            })
                            await fetch("https://php.caminantes.cl/loglogin.php?nombre='"
                                + response.data.data[0].NOMUSUARIO + ' ' + grupo + ' '
                                + inputs.username + ' ' + new Date().toLocaleString() + "'", {
                                method: 'POST',
                                mode: 'no-cors'
                            });
                            
                        } else {
                            document.getElementById('msgError').innerText = "Vuelve a intentar, Revisa tu Rut y Password"
                            setOk(!ok)
                        }
                    }
                    else {
                        setLogged(false);
                        setInputs([])
                        inputpass.value = '';
                        inputuser.value = '';
                        
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
            document.getElementById('username').value = ''
            document.getElementById('password').value = ''
            
        }
    }
    useEffect(() => {
        setInputs([])
        document.getElementById('username').value = ''
        document.getElementById('password').value = ''
        document.getElementById('msgError').innerText = ""
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className=''>
            <div hidden={logged} id='cajalogin' 
                className="mx-auto rounded-lg  min-w-64 bg-transparent bg-rose-400 bg-cover bg-no-repeat w-full" >
                <div>
                    <h2
                        className=" mt-4 pt-1 text-center font-extrabold text-2xl tracking-tight text-white">
                       Tu Camino Familiar
                    </h2>
                </div>
                <form className="mt-1 space-y-6 px-8 rounded-lg " method="GET" >
                    <div className="rounded-lg">
                        <div id='user' className='pb-1 rounded-lg font-extrabold'>
                            <label htmlFor="username" className="sr-only">
                                Rut
                            </label>
                            <input
                                onChange={handleChanges}
                                id="username"
                                name="username"
                                type="text"
                                autoComplete="true"
                                required
                                className=" relative block w-full appearance-none rounded-lg rounded-t-md border border-grey500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Rut"
                            />
                        </div>
                        <div id='pass' className='pb-1 rounded-lg  font-extrabold' >
                            <label htmlFor="password" className="sr-only">
                                Clave
                            </label>
                            <input
                                onChange={handleChanges}
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="true"
                                required
                                className="block 
                                    w-full appearance-none rounded-lg rounded-b-md 
                                    border border-grey500 px-3 py-2 text-gray-900 
                                    placeholder-gray-500 focus:z-10 focus:border-indigo-500 
                                    focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Tu clave caminantes"
                            />
                        </div>
                        <div className='pb-8'>
                            <button
                                onClick={validaPass}
                                type="submit"
                                className="group relative flex 
                                            w-full justify-center rounded-md border 
                                            border-transparent bg-violet-700 py-2 px-4
                                            text-xl font-extrabold text-white hover:bg-indigo-700 
                                                            focus:outline-none focus:ring-2 focus:ring-indigo-500 
                                                            focus:ring-offset-2">


                                Ingresar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div id="msgError" className="text-xl font-extrabold"></div>
        </div>

    )
}

export default Signin;
