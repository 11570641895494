import { React, useState } from "react";
import Signin from "./Signin";
import Camino from "./Camino"
import useSessionStorageState from 'use-session-storage-state'

export default function Navbar() {
    const [logged, setLogged] = useSessionStorageState('logged', { defaultValue: false })
    const [pagina, setPagina] = useState('inicio')
    const [ok, setOk] = useState(true)
    const salir = () => {
        localStorage.clear()
        sessionStorage.clear()
        setLogged(false);
        setOk(!ok);
        setPagina('inicio')
    }
    return (
        <div className="flex flex-col lg:flex-row col-span-3 w-full h-window lg:hfull
               bg-violet-500 lg:bg-[url('./rainbow.png')] 
                bg-gradient-to-r from-cyan-500 via-violet-400 to-violet-400">
            <div id="titulo-logo-botones" className="flex-none lg:w-1/3 colspan-1 ">
                <div className="py-2 px-1 mx-auto">
                    <div id="titulo"
                        className=" font-extrabold text-white text-5xl text-center pt-4 mx-auto">
                        {'CENTRO DE TERAPIAS CAMINANTES'}
                    </div>
                    <div id="bajada" className="flex">
                        <div className="flex pt-3 text-pink-900 lg:text-3xl text-3xl 
                            text-center font-extrabold  ">
                            {'Terapia, Educación y Capacitación en Autismo y Neurodivergencia'}
                        </div>
                    </div>
                    <div id="logo" className="pt-1 gap-1">
                        <img id="logo" alt="logo" src="./portada.png"
                            className='rounded-lg mx-auto'
                            onDoubleClick={() => window.open('https://www.caminantes.cl/centro')} />
                    </div>
                    <div id="Whatsapp" className="pt-2 mx-auto ">
                        <a target="_blank" href="https://wa.me/56975114334?text=Hola Dolores, mi nombre es: " rel="noreferrer">
                            <img className="h-12 mx-auto" src="./Whatsapp.png" alt="wap" ></img>
                        </a>
                    </div>
                    <div id="boton-login-salir" className="my-auto mx-auto text-center pt-2 font-extrabold">
                        <button hidden={logged}
                            className="bg-blue-500 text-3xl font-extrabold  h-10 text-white rounded-lg px-6"
                            onClick={() => {
                                pagina === 'inicio' ?
                                    setPagina('login')
                                    : pagina === 'login' ?
                                        setPagina('') : setPagina('login')
                            }}>
                            {'Reservar Hora'}
                        </button>
                        <button
                            hidden={!logged}
                            className="bg-blue-500 text-3xl w-auto h-8 font-extrabold text-white rounded-lg px-2"
                            onClick={() => { salir() }}>
                            {'Salir'}
                        </button>
                    </div>
                </div>
            </div>
            <div id="pagina" className=" flex-none lg:w-1/3 col-span-1 lg:pt-4 rounded-lg">
                {pagina === 'camino' ? <Camino /> : ''}
                {pagina === 'login' ? <Signin /> : ''}
            </div>
            <div id="especialidades-o-cajalogin" className="lg:w-1/3 col-span-1 flex-none rounded-lg lg:pt-8 pt-0">
                <div id="Especialidades" className="flex h-fit">
                    <div className="px-2 text-xl lg:text-2xl mx-auto rounded-lg">
                        <div className="text-center px-6 w-fit mx-auto py-1 rounded-lg bg-blue-500 font-extrabold text-3xl text-white">
                            Especialidades
                        </div>
                        <div className="flex flex-col h-fit text-white">
                            <ul className=" px-4 gap-1 font-extrabold text-2xl lg:text-3xl pt-3">
                                <li className="hover:text-white hover:bg-blue-500 cursor-pointer rounded-lg px-2 lg:py-3 py-2 pt-2 lg:pt-3">Fonoaudiología</li>
                                <li className="hover:text-white hover:bg-blue-500 cursor-pointer rounded-lg px-2 lg:py-3 py-2 pt-2 lg:pt-3">Psicología</li>
                                <li className="hover:text-white hover:bg-blue-500 cursor-pointer rounded-lg px-2 lg:py-3 py-2 pt-2 lg:pt-3">Educación Diferencial</li>
                                <li className="hover:text-white hover:bg-blue-500 cursor-pointer rounded-lg px-2 lg:py-3 py-2 pt-2 lg:pt-3">Terapia Ocupacional</li>
                                <li className="hover:text-white hover:bg-blue-500 cursor-pointer rounded-lg px-2 lg:py-3 py-2 pt-2 lg:pt-3">Psicopedagogía</li>
                                <li className="hover:text-white hover:bg-blue-500 cursor-pointer rounded-lg px-2 lg:py-3 py-2 pt-2 lg:pt-3">Coaching Comunicacional</li>
                                <li className="hover:text-white hover:bg-blue-500 cursor-pointer rounded-lg px-2 lg:py-3 py-2 pt-2 lg:pt-3">Asesoría a Familias</li>
                                <li className="hover:text-white hover:bg-blue-500 cursor-pointer rounded-lg px-2 lg:py-3 py-2 pt-2 lg:pt-3">Capacitación a Profesionales, Familias y Cuidadores</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

