import React from "react"

export default function Caminodisplay(ev) {
    return (
        <div id="caminar"
            className=" bg-blue-600 w-48 grid grow-0 gap-1 border-orange-500 border-2 rounded-lg py-2 px-0">
            <div
                className='mx-auto w-44 h-8 text-nowrap text-ellipsis overflow-hidden text-white text-center bg-sky-500 py-1 rounded-lg text-sm px-1'>
                {ev.NOMCAMINANTE}
            </div>
            <div className='mx-auto w-44 h-8 text-white text-center bg-violet-500 py-2 rounded-lg text-sm'>
                {ev.NOMGUIA}
            </div>
            <div className='mx-auto w-44 h-8 text-white text-center bg-violet-500 py-2 rounded-lg text-sm'>
                {ev.PROFESION}
            </div>
            <img className=' mx-auto rounded-lg max-w-44 min-w-44 max-h-44 min-h-44'
                src={'https://www.caminantes.cl/centro/images/guia/' + ev.CODGUIA + ".jpg"} alt={ev.id}>
            </img>
            <div className=" text-sm text-white font-semibold mx-auto ">
                {'Dia: '}{new Date(ev.start).toLocaleDateString()}{" Hra: "}
                {new Date(ev.start).toLocaleTimeString()}
            </div>
            <div className="mx-auto px-1 grid  pt-1">
                <a rel='noreferrer'
                    className="font-semibold text-sm w-44 h-8 px-1 text-white my-auto py-1  text-center bg-violet-500 rounded-lg"
                    target='_blank' download={true}
                    href={'https://escuela.caminantes.cl/docs/' + ev.nomdoc}>
                    {'Comprobante de Reserva'}
                </a>
            </div>
        </div>

    )
}

